import { cloneDeep as _cloneDeep } from 'lodash';

import krishaApi from '@/api/krisha.js';

const MODULE_STATE = {
  isLoading: false,
  data: null,
  errors: null,
};

const state = {
  sid: _cloneDeep(MODULE_STATE),
  user: _cloneDeep(MODULE_STATE),
  requests: _cloneDeep(MODULE_STATE),
};

const mutations = {
  SET_LOADING: (state, { key, status }) => {
    state[key].isLoading = status;
  },

  SET_DATA: (state, { key, data }) => {
    state[key].data = data;
  },

  SET_ERROR: (state, { key, error }) => {
    state[key].error = error;
  },

  CLEAR_STATE: (state, key) => {
    state[key] = _cloneDeep(MODULE_STATE);
  },
};

const actions = {
  auth({ commit, state }) {
    if (state.sid.data) return;

    commit('SET_LOADING', { key: 'sid', status: true });
    return krishaApi
      .auth()
      .then(({ headers, user }) => {
        if (!headers) throw new Error('SID не найден');

        commit('SET_DATA', { key: 'sid', data: headers });
        commit('SET_DATA', { key: 'user', data: user });
      })
      .catch(() => {
        commit('SET_ERROR', { key: 'sid', status: true });
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'sid', status: false });
      });
  },

  getRequestList({ commit, state }) {
    commit('SET_LOADING', { key: 'requests', status: true });
    return krishaApi
      .getRequestList(state.sid.data)
      .then((data) => {
        const requests = data.ActiveRequests;
        commit('SET_DATA', { key: 'requests', data: requests });
      })
      .finally(() => {
        commit('SET_LOADING', { key: 'requests', status: false });
      });
  },

  addRequest({ commit, state }, params) {
    commit('SET_LOADING', { key: 'requests', status: true });
    return krishaApi.addRequest(state.sid.data, params).finally(() => {
      commit('SET_LOADING', { key: 'requests', status: false });
    });
  },

  addMessage({ commit, state }, params) {
    commit('SET_LOADING', { key: 'requests', status: true });
    return krishaApi.addMessage(state.sid.data, params).finally(() => {
      commit('SET_LOADING', { key: 'requests', status: false });
    });
  },

  addFileToRequest({ commit, state }, params) {
    commit('SET_LOADING', { key: 'requests', status: true });
    return krishaApi.addFileToRequest(state.sid.data, params).finally(() => {
      commit('SET_LOADING', { key: 'requests', status: false });
    });
  },

  getRequestInfo({ state }, params) {
    return krishaApi
      .getRequestInfo(state.sid.data, params)
      .then((data) => data?.requests[0] || null);
  },

  clearState({ commit }, stateKey) {
    commit('CLEAR_STATE', stateKey);
  },
};

export default {
  name: 'krisha',
  namespaced: true,
  state,
  mutations,
  actions,
};
