import { aeroApiMng } from '@/plugins/axios.js';

const API_ENDPOINT = 'https://help.1caero.ru/KrishaApi';

function auth() {
  return aeroApiMng.get(`${API_ENDPOINT}/Auth.ashx`).then((res) => {
    const headers = res.headers;

    if (!('sid' in headers)) return null;

    return {
      headers: headers['sid'],
      user: res.data
    };
  });
}

function getRequestList(sid) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/GetRequestList.ashx`, {
      headers: {
        sid,
      },
    })
    .then((res) => res?.data);
}

function addRequest(sid, params) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/AddRequest_Android.ashx`, {
      headers: { sid },
      params,
    })
    .then((res) => res);
}

function addMessage(sid, params) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/AddConsultantMessage.ashx`, {
      headers: { sid },
      params,
    })
    .then((res) => res);
}

function addFileToRequest(sid, { formData, params }) {
  const queryString = new URLSearchParams(params).toString();

  return aeroApiMng
    .post(`${API_ENDPOINT}/AddFileToRequest.ashx?${queryString}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'sid': sid,
      }
    })
    .then((res) => res);
}

function getRequestInfo(sid, params) {
  return aeroApiMng
    .get(`${API_ENDPOINT}/GetRequestWithMessages.ashx`, {
      headers: { sid },
      params,
    })
    .then((res) => res?.data);
}

export default {
  auth,
  getRequestList,
  getRequestInfo,
  addRequest,
  addMessage,
  addFileToRequest,
};
