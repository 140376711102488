const authorizedRoutes = [
  {
    path: '*',
    name: 'Error404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      title: 'КСП | Страница не найдена',
    },
  },

  {
    path: '/',
    name: 'Index',
    component: () => import('@/views/Index.vue'),
    meta: {
      title: 'КСП | Главная',
    },
  },

  // [ START ] administration
  {
    path: '/administration',
    name: 'AdministrationMain',
    component: () => import('@/views/administration/Main.vue'),
    meta: {
      title: 'КСП | Администрирование',
    },
  },

  // { START } commons

  // support routes
  {
    path: '/administration/support-routes',
    name: 'SupportRoutes',
    component: () => import('@/views/administration/SupportRoutes.vue'),
    meta: {
      title: 'КСП | Маршруты',
    },
  },
  {
    path: '/administration/support-routes/create',
    name: 'SupportRouteCreate',
    component: () => import('@/views/administration/SupportRouteCreate.vue'),
    meta: {
      title: 'КСП | Создание маршрута',
    },
  },
  {
    path: '/administration/support-routes/edit/:id',
    name: 'SupportRouteEdit',
    component: () => import('@/views/administration/SupportRouteEdit.vue'),
    meta: {
      title: 'КСП | Редактирование маршрута',
    },
  },

  // support gis types
  {
    path: '/administration/support-gis-types',
    name: 'SupportGisTypes',
    component: () => import('@/views/administration/SupportGisTypes.vue'),
    meta: {
      title: 'КСП | Типы заявок',
    },
  },
  {
    path: '/administration/support-gis-types/create',
    name: 'SupportGisTypeCreate',
    component: () => import('@/views/administration/SupportGisTypeCreate.vue'),
    meta: {
      title: 'КСП | Создание типа заявок',
    },
  },
  {
    path: '/administration/support-gis-types/edit/:id',
    name: 'SupportGisTypeEdit',
    component: () => import('@/views/administration/SupportGisTypeEdit.vue'),
    meta: {
      title: 'КСП | Редактирование типа заявок',
    },
  },

  // customer settings database types
  {
    path: '/administration/customer-settings-database-types',
    name: 'CustomerSettingsDatabaseTypes',
    component: () =>
      import('@/views/administration/CustomerSettingsDatabaseTypes.vue'),
    meta: {
      title: 'КСП | Виды БД',
    },
  },
  {
    path: '/administration/customer-settings-database-types/create',
    name: 'CustomerSettingsDatabaseTypeCreate',
    component: () =>
      import('@/views/administration/CustomerSettingsDatabaseTypeCreate.vue'),
    meta: {
      title: 'КСП | Создание вида БД',
    },
  },
  {
    path: '/administration/customer-settings-database-types/edit/:id',
    name: 'CustomerSettingsDatabaseTypeEdit',
    component: () =>
      import('@/views/administration/CustomerSettingsDatabaseTypeEdit.vue'),
    meta: {
      title: 'КСП | Редактирование вида БД',
    },
  },

  // support initiation statuses
  {
    path: '/administration/support-initiation-statuses',
    name: 'SupportInitiationStatuses',
    component: () =>
      import('@/views/administration/SupportInitiationStatuses.vue'),
    meta: {
      title: 'КСП | Статусы',
    },
  },
  {
    path: '/administration/support-initiation-statuses/create',
    name: 'SupportInitiationStatuseCreate',
    component: () =>
      import('@/views/administration/SupportInitiationStatuseCreate.vue'),
    meta: {
      title: 'КСП | Создание статуса',
    },
  },
  {
    path: '/administration/support-initiation-statuses/edit/:id',
    name: 'SupportInitiationStatuseEdit',
    component: () =>
      import('@/views/administration/SupportInitiationStatuseEdit.vue'),
    meta: {
      title: 'КСП | Редактирование статуса',
    },
  },

  // user default connections
  {
    path: '/administration/folder-default-connections',
    name: 'FolderDefaultConnections',
    component: () =>
      import('@/views/administration/FolderDefaultConnections.vue'),
    meta: {
      title: 'КСП | Подключения по умолчанию сетевой папки',
    },
  },
  {
    path: '/administration/folder-default-connection/create',
    name: 'FolderDefaultConnectionCreate',
    component: () =>
      import('@/views/administration/FolderDefaultConnectionCreate.vue'),
    meta: {
      title: 'КСП | Создание подключения по умолчанию сетевой папки',
    },
  },
  {
    path: '/administration/folder-default-connection/edit/:id',
    name: 'FolderDefaultConnectionEdit',
    component: () =>
      import('@/views/administration/FolderDefaultConnectionEdit.vue'),
    meta: {
      title: 'КСП | Редактирование подключения по умолчанию сетевой папки',
    },
  },

  // hosts
  {
    path: '/administration/hosts',
    name: 'Hosts',
    component: () => import('@/views/administration/Hosts.vue'),
    meta: {
      title: 'КСП | Хосты',
    },
  },
  {
    path: '/administration/host/create',
    name: 'HostCreate',
    component: () => import('@/views/administration/HostCreate.vue'),
    meta: {
      title: 'КСП | Создание хоста',
    },
  },
  {
    path: '/administration/host/edit/:id',
    name: 'HostEdit',
    component: () => import('@/views/administration/HostEdit.vue'),
    meta: {
      title: 'КСП | Редактирование хоста',
    },
  },

  // servers
  {
    path: '/administration/servers',
    name: 'Servers',
    component: () => import('@/views/administration/Servers.vue'),
    meta: {
      title: 'КСП | Сервера',
    },
  },
  {
    path: '/administration/server/create',
    name: 'ServerCreate',
    component: () => import('@/views/administration/ServerCreate.vue'),
    meta: {
      title: 'КСП | Создание сервера',
    },
  },
  {
    path: '/administration/server/edit/:id',
    name: 'ServerEdit',
    component: () => import('@/views/administration/ServerEdit.vue'),
    meta: {
      title: 'КСП | Редактирование сервера',
    },
  },

  // OneCPinCodes
  {
    path: '/administration/one-c-pin-codes',
    name: 'OneCPinCodes',
    component: () => import('@/views/administration/OneCPinCodes.vue'),
    meta: {
      title: 'КСП | Пин-коды 1с',
    },
  },
  {
    path: '/administration/one-c-pin-code/create',
    name: 'OneCPinCodeCreate',
    component: () => import('@/views/administration/OneCPinCodeCreate.vue'),
    meta: {
      title: 'КСП | Создание пин-кода 1с',
    },
  },
  {
    path: '/administration/one-c-pin-code/edit/:id',
    name: 'OneCPinCodeEdit',
    component: () => import('@/views/administration/OneCPinCodeEdit.vue'),
    meta: {
      title: 'КСП | Редактирование пин-кода 1с',
    },
  },

  // support req templates
  {
    path: '/administration/support-req-templates',
    name: 'SupportReqTemplates',
    component: () => import('@/views/administration/SupportReqTemplates.vue'),
    meta: {
      title: 'КСП | Шаблоны заявок',
    },
  },
  {
    path: '/administration/support-req-templates/create',
    name: 'SupportReqTemplateCreate',
    component: () =>
      import('@/views/administration/SupportReqTemplateCreate.vue'),
    meta: {
      title: 'КСП | Создание шаблона заявок',
    },
  },
  {
    path: '/administration/support-req-templates/edit/:id',
    name: 'SupportReqTemplateEdit',
    component: () =>
      import('@/views/administration/SupportReqTemplateEdit.vue'),
    meta: {
      title: 'КСП | Редактирование шаблона заявок',
    },
  },

  // krisha help menu
  {
    path: '/administration/krisha-help-menu',
    name: 'KrishaHelpMenu',
    component: () => import('@/views/administration/KrishaHelpMenu.vue'),
    meta: {
      title: 'КСП | Меню справки',
    },
  },
  {
    path: '/administration/krisha-help-menu/create',
    name: 'KrishaHelpMenuCreate',
    component: () => import('@/views/administration/KrishaHelpMenuCreate.vue'),
    meta: {
      title: 'КСП | Создание меню справки',
    },
  },
  {
    path: '/administration/krisha-help-menu/edit/:id',
    name: 'KrishaHelpMenuEdit',
    component: () => import('@/views/administration/KrishaHelpMenuEdit.vue'),
    meta: {
      title: 'КСП | Редактирование меню справки',
    },
  },

  // missed call templates groupes
  {
    path: '/administration/missed-call-templates-groupes',
    name: 'MissedCallTemplatesGroups',
    component: () =>
      import('@/views/administration/MissedCallTemplatesGroups.vue'),
    meta: {
      title: 'Шаблоны ответов для пропущенных звонков',
    },
  },

  {
    path: '/administration/missed-call-templates-groupe/create',
    name: 'MissedCallTemplatesGroupCreate',
    component: () =>
      import('@/views/administration/MissedCallTemplatesGroupCreate.vue'),
    meta: {
      title: 'КСП | Создание группы шаблонов ответов для пропущенных звонков',
    },
  },

  {
    path: '/administration/missed-call-templates-groupe/edit/:id',
    name: 'MissedCallTemplatesGroupEdit',
    component: () =>
      import('@/views/administration/MissedCallTemplatesGroupEdit.vue'),
    meta: {
      title:
        'КСП | Редактирование группы шаблонов ответов для пропущенных звонков',
    },
  },

  // missed call templates
  {
    path: '/administration/missed-call-templates',
    name: 'MissedCallTemplates',
    component: () => import('@/views/administration/MissedCallTemplates.vue'),
    meta: {
      title: 'Шаблоны ответов для пропущенных звонков',
    },
  },

  {
    path: '/administration/missed-call-templates/create',
    name: 'MissedCallTemplateCreate',
    component: () =>
      import('@/views/administration/MissedCallTemplateCreate.vue'),
    meta: {
      title: 'КСП | Создание шаблона ответов для пропущенных звонков',
    },
  },

  {
    path: '/administration/missed-call-templates/edit/:id',
    name: 'MissedCallTemplateEdit',
    component: () =>
      import('@/views/administration/MissedCallTemplateEdit.vue'),
    meta: {
      title: 'КСП | Редактирование шаблона ответов для пропущенных звонков',
    },
  },

  // call senter bases
  {
    path: '/administration/call-center-bases',
    name: 'CallCenterBases',
    component: () => import('@/views/administration/CallCenterBases.vue'),
    meta: {
      title: 'Базы для колцентра',
    },
  },
  {
    path: '/administration/call-center-bases/create',
    name: 'CallCenterBaseCreate',
    component: () => import('@/views/administration/CallCenterBaseCreate.vue'),
    meta: {
      title: 'КСП | Создание базы для колл центра',
    },
  },
  {
    path: '/administration/call-center-bases/edit/:id',
    name: 'CallCenterBaseEdit',
    component: () => import('@/views/administration/CallCenterBaseEdit.vue'),
    meta: {
      title: 'КСП | Редактирование базы для колл центра',
    },
  },

  // base-phones matching
  {
    path: '/administration/base-phone-matching',
    name: 'BasePhoneMatching',
    component: () => import('@/views/administration/BasePhoneMatching.vue'),
    meta: {
      title: 'КСП | Соответствие баз и телефонов для роботов',
    },
  },
  {
    path: '/administration/base-phone-matching/create',
    name: 'BasePhoneMatchingCreate',
    component: () =>
      import('@/views/administration/BasePhoneMatchingCreate.vue'),
    meta: {
      title: 'КСП | Создание соответствия базы и телефона',
    },
  },
  {
    path: '/administration/base-phone-matching/edit/:id',
    name: 'BasePhoneMatchingEdit',
    component: () => import('@/views/administration/BasePhoneMatchingEdit.vue'),
    meta: {
      title: 'КСП | Редактирование соответствия базы и телефона',
    },
  },

  {
    path: '/administration/requests-verifications-report',
    name: 'RequestsVerificationsReport',
    component: () =>
      import('@/views/administration/RequestsVerificationsReport.vue'),
    meta: {
      title: 'КСП | Журнал проверки заявок КЦ',
    },
  },

  {
    path: '/administration/requests-Verifications-summary-report',
    name: 'RequestsVerificationsSummaryReport',
    component: () =>
      import('@/views/administration/RequestsVerificationsSummaryReport.vue'),
    meta: {
      title: 'КСП | Сводный отчет по ошибкам КЦ',
    },
  },

  // account employees
  {
    path: '/administration/account-employees',
    name: 'AccountEmployees',
    component: () => import('@/views/administration/AccountEmployees.vue'),
    meta: {
      title: 'КСП | Список сотрудников',
    },
  },
  {
    path: '/administration/account-employees/create',
    name: 'AccountEmployeeCreate',
    component: () => import('@/views/administration/AccountEmployeeCreate.vue'),
    meta: {
      title: 'КСП | Создание сотрудника',
    },
  },
  {
    path: '/administration/account-employees/edit/:id',
    name: 'AccountEmployeeEdit',
    component: () => import('@/views/administration/AccountEmployeeEdit.vue'),
    meta: {
      title: 'КСП | Редактирование сотрудника',
    },
  },

  // access rights
  {
    path: '/administration/access-rights/roles',
    name: 'Roles',
    component: () => import('@/views/administration/access-rights/Roles.vue'),
    meta: {
      title: 'КСП | Роли сотрудников',
    },
  },
  {
    path: '/administration/access-rights/roles/create',
    name: 'RoleCreate',
    component: () =>
      import('@/views/administration/access-rights/RoleCreate.vue'),
    meta: {
      title: 'КСП | Создание роли сотрудника',
    },
  },
  {
    path: '/administration/access-rights/roles/edit/:id',
    name: 'RoleEdit',
    component: () =>
      import('@/views/administration/access-rights/RoleEdit.vue'),
    meta: {
      title: 'КСП | Редактирование роли сотрудника',
    },
  },

  // departments
  {
    path: '/administration/departments',
    name: 'Departments',
    component: () => import('@/views/administration/Departments.vue'),
    meta: {
      title: 'КСП | Подразделения',
    },
  },
  {
    path: '/administration/departments/create',
    name: 'DepartmentCreate',
    component: () => import('@/views/administration/DepartmentCreate.vue'),
    meta: {
      title: 'КСП | Создание подразделения',
    },
  },
  {
    path: '/administration/departments/edit/:id',
    name: 'DepartmentEdit',
    component: () => import('@/views/administration/DepartmentEdit.vue'),
    meta: {
      title: 'КСП | Редактирование подразделения',
    },
  },

  // launcher 1C versions
  {
    path: '/administration/launcher-1c-versions',
    name: 'Launcher1CVersions',
    component: () => import('@/views/administration/Launcher1CVersions.vue'),
    meta: {
      title: 'КСП | Версии клиента 1С',
    },
  },
  {
    path: '/administration/launcher-1c-versions/create',
    name: 'Launcher1CVersionCreate',
    component: () =>
      import('@/views/administration/Launcher1CVersionCreate.vue'),
    meta: {
      title: 'КСП | Создание версии клиента 1C',
    },
  },
  {
    path: '/administration/launcher-1c-versions/edit/:id',
    name: 'Launcher1CVersionEdit',
    component: () => import('@/views/administration/Launcher1CVersionEdit.vue'),
    meta: {
      title: 'КСП | Редактирование версии клиента 1C',
    },
  },

  // launcher platforms
  {
    path: '/administration/launcher-platforms',
    name: 'LauncherPlatforms',
    component: () => import('@/views/administration/LauncherPlatforms.vue'),
    meta: {
      title: 'КСП | Настройка платформ',
    },
  },
  {
    path: '/administration/launcher-platforms/create',
    name: 'LauncherPlatformCreate',
    component: () =>
      import('@/views/administration/LauncherPlatformCreate.vue'),
    meta: {
      title: 'КСП | Создание настройки платформ',
    },
  },
  {
    path: '/administration/launcher-platforms/edit/:id',
    name: 'LauncherPlatformEdit',
    component: () => import('@/views/administration/LauncherPlatformEdit.vue'),
    meta: {
      title: 'КСП | Редактирование настройки платформ',
    },
  },

  // type bases 1C
  {
    path: '/administration/type-bases-1c',
    name: 'TypeBases1C',
    component: () => import('@/views/administration/TypeBases1C.vue'),
    meta: {
      title: 'КСП | Конфигурации',
    },
  },
  {
    path: '/administration/type-bases-1c/create',
    name: 'TypeBase1CCreate',
    component: () => import('@/views/administration/TypeBase1CCreate.vue'),
    meta: {
      title: 'КСП | Создание конфигурации',
    },
  },
  {
    path: '/administration/type-bases-1c/edit/:id',
    name: 'TypeBase1CEdit',
    component: () => import('@/views/administration/TypeBase1CEdit.vue'),
    meta: {
      title: 'КСП | Редактирование конфигурации',
    },
  },

  // bases 1C
  {
    path: '/administration/bases-1c',
    name: 'Bases1C',
    component: () => import('@/views/administration/Bases1C.vue'),
    meta: {
      title: 'КСП | Базы 1С',
    },
  },
  {
    path: '/administration/bases-1c/create',
    name: 'Base1CCreate',
    component: () => import('@/views/administration/Base1CCreate.vue'),
    meta: {
      title: 'КСП | Создание базы 1С',
    },
  },
  {
    path: '/administration/bases-1c/edit/:id',
    name: 'Base1CEdit',
    component: () => import('@/views/administration/Base1CEdit.vue'),
    meta: {
      title: 'КСП | Редактирование базы 1С',
    },
  },

  // base 1C log
  {
    path: '/administration/bases-1c-log',
    name: 'Base1CLog',
    component: () => import('@/views/administration/Base1CLog.vue'),
    meta: {
      title: 'КСП | Логирование изменений в базах 1С',
    },
  },

  {
    path: '/administration/access-rights/employee-report',
    name: 'EmployeeReport',
    component: () =>
      import('@/views/administration/access-rights/EmployeeReport.vue'),
    meta: {
      title: 'КСП | Отчет по выполненным заявкам сотрудников',
    },
  },

  {
    path: '/administration/access-rights/requests-report',
    name: 'RequestsReport',
    component: () =>
      import('@/views/administration/access-rights/RequestsReport.vue'),
    meta: {
      title: 'КСП | Отчет по текущим заявкам сотрудника в работе',
    },
  },
  // { END } commons

  // { START } reports
  {
    path: '/administration/reports/structure-applications-time-day-report',
    name: 'StructureApplicationsTimeDayReport',
    component: () =>
      import(
        '@/views/administration/reports/StructureApplicationsTimeDayReport.vue'
      ),
    meta: {
      title: 'КСП | Анализ заявок ОСС и ОСП по дням месяца за период',
    },
  },

  {
    path: '/administration/reports/structure-applications-time-hours-report',
    name: 'StructureApplicationsTimeHoursReport',
    component: () =>
      import(
        '@/views/administration/reports/StructureApplicationsTimeHoursReport.vue'
      ),
    meta: {
      title: 'КСП | Анализ заявок ОСС и ОСП по часам в дне за период',
    },
  },

  {
    path: '/administration/reports/debt-requests-report',
    name: 'DebtRequestsReport',
    component: () =>
      import('@/views/administration/reports/DebtRequestsReport.vue'),
    meta: {
      title: 'КСП | Отчет по мониторингу ГИС ЖКХ запросы ОСЗН',
    },
  },

  {
    path: '/administration/reports/payments-vs-paychecks-report',
    name: 'PaymentsVsPaychecksReport',
    component: () =>
      import('@/views/administration/reports/PaymentsVsPaychecksReport.vue'),
    meta: {
      title: 'КСП | Сводный отчет по пробитию чеков орандж - дата',
    },
  },

  {
    path: '/administration/reports/paychecks-errors-report',
    name: 'PaychecksErrorsReport',
    component: () =>
      import('@/views/administration/reports/PaychecksErrorsReport.vue'),
    meta: {
      title: 'КСП | Отчет по ошибкам формирования чеков',
    },
  },

  {
    path: '/administration/reports/gis-export-common-statistics-report',
    name: 'GisExportCommonStatisticsReport',
    component: () =>
      import(
        '@/views/administration/reports/GisExportCommonStatisticsReport.vue'
      ),
    meta: {
      title: 'КСП | Сводный паспорт выгрузки в ГИС ЖКХ',
    },
  },

  {
    path: '/administration/reports/db-fragmentations-report',
    name: 'DbFragmentationsReport',
    component: () =>
      import('@/views/administration/reports/DbFragmentationsReport.vue'),
    meta: {
      title: 'КСП | Статистика фрагментации индексов',
    },
  },

  {
    path: '/administration/reports/pools-stat-report',
    name: 'PoolsStatReport',
    component: () =>
      import('@/views/administration/reports/PoolsStatReport.vue'),
    meta: {
      title: 'КСП | Отчет по пулам',
    },
  },

  {
    path: '/administration/reports/meters-checkup-report',
    name: 'MetersCheckupReport',
    component: () =>
      import('@/views/administration/reports/MetersCheckupReport.vue'),
    meta: {
      title: 'КСП | Сводный отчет по датам поверки приборов учета',
    },
  },

  {
    path: '/administration/reports/meter-recheck-calls-report',
    name: 'MeterRecheckCallsReport',
    component: () =>
      import('@/views/administration/reports/MeterRecheckCallsReport.vue'),
    meta: {
      title: 'КСП | Результаты ответов на звонки о необходимости поверить ПУ',
    },
  },
  // { END } reports

  // [ START ] integration
  {
    path: '/administration/integration/doma-ai-integration',
    name: 'DomaAiIntegration',
    component: () =>
      import('@/views/administration/integration/DomaAiIntegration.vue'),
    meta: {
      title: 'Интеграция с "Дома АИ"',
    },
  },
  {
    path: '/administration/integration/doma-ai-integration/new',
    name: 'DomaAiIntegrationNew',
    component: () =>
      import('@/views/administration/integration/DomaAiIntegrationNew.vue'),
    meta: {
      title: 'Создание интеграции с "Дома АИ"',
    },
  },
  {
    path: '/administration/integration/doma-ai-integration/edit/:id',
    name: 'DomaAiIntegrationEdit',
    component: () =>
      import('@/views/administration/integration/DomaAiIntegrationEdit.vue'),
    meta: {
      title: 'Редактирование интеграции с "Дома АИ"',
    },
  },
  // [ END ] integration

  // [ END ] administration

  // [ START ] monitoring
  {
    path: '/monitoring',
    name: 'MonitoringMain',
    component: () => import('@/views/monitoring/Main.vue'),
    meta: {
      title: 'КСП | Мониторинг',
    },
  },
  // { START } ATS access
  {
    path: '/monitoring/ats/ats-access',
    name: 'AtsAccess',
    component: () => import('@/views/monitoring/ats/AtsAccess.vue'),
    meta: {
      title: 'КСП | Аккаунты АТС домофонов',
    },
  },
  // { END } ATS access
  // { START } reports
  {
    path: '/monitoring/reports/report-is-requests',
    name: 'ReportISRequests',
    component: () => import('@/views/monitoring/reports/ReportISRequests.vue'),
    meta: {
      title: 'КСП | Отчет по заявкам ИС',
    },
  },

  {
    path: '/monitoring/reports/ads-requests-performing-report',
    name: 'AdsRequestsPerformingReport',
    component: () =>
      import('@/views/monitoring/reports/AdsRequestsPerformingReport.vue'),
    meta: {
      title: 'КСП | Отчет по заявкам АДС во всех базах',
    },
  },

  {
    path: '/monitoring/reports/partners-requests-report',
    name: 'PartnersRequestsReport',
    component: () =>
      import('@/views/monitoring/reports/PartnersRequestsReport.vue'),
    meta: {
      title: 'КСП | Отчет о заявках партнеров',
    },
  },

  {
    path: '/monitoring/reports/request-query-report',
    name: 'RequestQueryReport',
    component: () =>
      import('@/views/monitoring/reports/RequestQueryReport.vue'),
    meta: {
      title: 'КСП | Сводный отчет по очереди заданий',
    },
  },

  {
    path: '/monitoring/reports/sessions-log',
    name: 'SessionsLog',
    component: () => import('@/views/monitoring/reports/SessionsLog.vue'),
    meta: {
      title: 'КСП | Журнал авторизации',
    },
  },

  {
    path: '/monitoring/reports/registrations',
    name: 'RegistrationsReport',
    component: () =>
      import('@/views/monitoring/reports/RegistrationsReport.vue'),
    meta: {
      title: 'КСП | Отчет по регистрациям',
    },
  },

  {
    path: '/monitoring/reports/employee-call',
    name: 'EmployeeCallReport',
    component: () =>
      import('@/views/monitoring/reports/EmployeeCallReport.vue'),
    meta: {
      title: 'КСП | Отчет по принятым звонкам сотрудников',
    },
  },

  {
    path: '/monitoring/reports/employee-call-day-report',
    name: 'EmployeeCallDayReport',
    component: () =>
      import('@/views/monitoring/reports/EmployeeCallDayReport.vue'),
    meta: {
      title: 'КСП | Отчет по принятым звонкам сотрудников за день',
    },
  },

  {
    path: '/monitoring/reports/customers-call-day-report',
    name: 'CustomersCallDayReport',
    component: () =>
      import('@/views/monitoring/reports/CustomersCallDayReport.vue'),
    meta: {
      title: 'КСП | Отчет по принятым звонкам за день по клиентам',
    },
  },
  // { END } reports

  // { START } payments and mobile application
  {
    path: '/monitoring/payments-and-mobile-application/mobile-users-by-databases-report',
    name: 'MobileUsersByDatabasesReport',
    component: () =>
      import(
        '@/views/monitoring/payments-and-mobile-application/MobileUsersByDatabasesReport.vue'
      ),
    meta: {
      title: 'КСП | Список мобильных пользователей по базам',
    },
  },
  {
    path: '/monitoring/payments-and-mobile-application/mobile-users-by-databases-full-report',
    name: 'MobileUsersByDatabasesFullReport',
    component: () =>
      import(
        '@/views/monitoring/payments-and-mobile-application/MobileUsersByDatabasesFullReport.vue'
      ),
    meta: {
      title: 'КСП | Отчет по мобильным пользователям сводный',
    },
  },
  {
    path: '/monitoring/payments-and-mobile-application/payments-registry-stats-report',
    name: 'PaymentsRegistryStatsReport',
    component: () =>
      import(
        '@/views/monitoring/payments-and-mobile-application/PaymentsRegistryStatsReport.vue'
      ),
    meta: {
      title: 'КСП | Отчет по реестрам оплат ЛК и МП',
    },
  },
  {
    path: '/monitoring/payments-and-mobile-application/payments-registry-report',
    name: 'PaymentsRegistryReport',
    component: () =>
      import(
        '@/views/monitoring/payments-and-mobile-application/PaymentsRegistryReport.vue'
      ),
    meta: {
      title: 'КСП | Отчет по реестрам оплат ЛК и МП помесячно',
    },
  },
  {
    path: '/monitoring/mobile-users-activity-report',
    name: 'MobileUsersActivityReport',
    component: () =>
      import(
        '@/views/monitoring/payments-and-mobile-application/MobileUsersActivityReport.vue'
      ),
    meta: {
      title: 'КСП | Отчет по количеству активных пользователей в МП и ЛК',
    },
  },

  {
    path: '/monitoring/payments-and-mobile-application/moe-accounts-report',
    name: 'MoeAccountsReport',
    component: () =>
      import(
        '@/views/monitoring/payments-and-mobile-application/MoeAccountsReport.vue'
      ),
    meta: {
      title: 'КСП | Сводный отчет по подключенным лиц. счетам МОЕ',
    },
  },
  // { END } payments and mobile application

  // { START } insurance
  // databases with insurances
  {
    path: '/monitoring/insurance/databases-with-insurances',
    name: 'DatabasesWithInsurances',
    component: () =>
      import('@/views/monitoring/insurance/DatabasesWithInsurances.vue'),
    meta: {
      title: 'КСП | Базы данных со страхованием',
    },
  },
  {
    path: '/monitoring/insurance/databases-with-insurances/create',
    name: 'DatabaseWithInsurancesCreate',
    component: () =>
      import('@/views/monitoring/insurance/DatabaseWithInsurancesCreate.vue'),
    meta: {
      title: 'КСП | Создание базы данных со страхованием',
    },
  },
  {
    path: '/monitoring/insurance/databases-with-insurances/edit/:id',
    name: 'DatabaseWithInsurancesEdit',
    component: () =>
      import('@/views/monitoring/insurance/DatabaseWithInsurancesEdit.vue'),
    meta: {
      title: 'КСП | Редактирование базы данных со страхованием',
    },
  },

  {
    path: '/monitoring/insurance/insurance-report',
    name: 'InsuranceReport',
    component: () => import('@/views/monitoring/insurance/InsuranceReport.vue'),
    meta: {
      title: 'КСП | Отчет по страхованию',
    },
  },
  // { END } insurance

  // { START } indications of metering devices
  {
    path: '/monitoring/indications-of-metering-devices/meter-values-reader-statistic',
    name: 'MeterValuesReaderStatistic',
    component: () =>
      import(
        '@/views/monitoring/indications-of-metering-devices/MeterValuesReaderStatistic.vue'
      ),
    meta: {
      title: 'КСП | Cбор данных показаний - фотографии',
    },
  },

  {
    path: '/monitoring/indications-of-metering-devices/meter-recheck-requests',
    name: 'MeterRecheckRequests',
    component: () =>
      import(
        '@/views/monitoring/indications-of-metering-devices/MeterRecheckRequests.vue'
      ),
    meta: {
      title: 'КСП | Заявки на поверку ИПУ',
    },
  },

  {
    path: '/monitoring/indications-of-metering-devices/meter-recheck-requests/edit/:id',
    name: 'MeterRecheckRequestsEdit',
    component: () =>
      import(
        '@/views/monitoring/indications-of-metering-devices/MeterRecheckRequestsEdit.vue'
      ),
    meta: {
      title: 'КСП | Просмотр списка ИПУ по заявке',
    },
  },
  // { END } indications of metering devices
  // [ END ] monitoring

  // [ START ] customers
  {
    path: '/customers',
    name: 'CustomersMain',
    component: () => import('@/views/customers/Main.vue'),
    meta: {
      title: 'КСП | Клиенты',
    },
  },
  // customers
  {
    path: '/customers/customers',
    name: 'Customers',
    component: () => import('@/views/customers/Customers.vue'),
    meta: {
      title: 'КСП | Клиенты',
    },
  },
  {
    path: '/customers/customers/create',
    name: 'CustomerCreate',
    component: () => import('@/views/customers/CustomerCreate.vue'),
    meta: {
      title: 'КСП | Создание клиента',
    },
  },
  {
    path: '/customers/customers/edit/:id',
    name: 'CustomerEdit',
    component: () => import('@/views/customers/CustomerEdit.vue'),
    meta: {
      title: 'КСП | Редактирование клиента',
    },
  },
  // ignored customers
  {
    path: '/customers/ignored-customers',
    name: 'IgnoredCustomers',
    component: () => import('@/views/customers/IgnoredCustomers.vue'),
    meta: {
      title: 'КСП | Клиенты, по которым не контролировать долги',
    },
  },
  {
    path: '/customers/ignored-customers/create',
    name: 'IgnoredCustomersCreate',
    component: () => import('@/views/customers/IgnoredCustomersNew.vue'),
    meta: {
      title: 'КСП | Создание клиента, по которым не контролировать долги',
    },
  },
  {
    path: '/customers/ignored-customers/edit/:id',
    name: 'IgnoredCustomersEdit',
    component: () => import('@/views/customers/IgnoredCustomersEdit.vue'),
    meta: {
      title: 'КСП | Редактирование клиента, по которым не контролировать долги',
    },
  },
  // ads bases
  {
    path: '/customers/ads-bases',
    name: 'AdsBases',
    component: () => import('@/views/customers/AdsBases.vue'),
    meta: {
      title: 'КСП | Базы АДС',
    },
  },
  {
    path: '/customers/ads-bases/create',
    name: 'AdsBasesCreate',
    component: () => import('@/views/customers/AdsBasesNew.vue'),
    meta: {
      title: 'КСП | Создание базы АДС',
    },
  },
  {
    path: '/customers/ads-bases/edit/:id',
    name: 'AdsBasesEdit',
    component: () => import('@/views/customers/AdsBasesEdit.vue'),
    meta: {
      title: 'КСП | Редактирование базы АДС',
    },
  },
  {
    path: '/customers/site-settings',
    name: 'SiteSettings',
    component: () => import('@/views/customers/SiteSettings.vue'),
    meta: {
      title: 'Настройки сайта',
    },
  },
  {
    path: '/customers/ads-desktop-report',
    name: 'AdsDesktopReport',
    component: () => import('@/views/customers/AdsDesktopReport.vue'),
    meta: {
      title: 'Отчет по выключению десктопных АДС',
    },
  },
  // chat-bot questions
  {
    path: '/customers/chat-bot-questions',
    name: 'ChatbotQuestions',
    component: () => import('@/views/customers/ChatbotQuestions.vue'),
    meta: {
      title: 'КСП | Вопросы для чат-бота',
    },
  },
  {
    path: '/customers/chat-bot-questions/create',
    name: 'ChatbotQuestionsCreate',
    component: () => import('@/views/customers/ChatbotQuestionsNew.vue'),
    meta: {
      title: 'КСП | Создание вопроса для чат-бота',
    },
  },
  {
    path: '/customers/chat-bot-questions/edit/:id',
    name: 'ChatbotQuestionsEdit',
    component: () => import('@/views/customers/ChatbotQuestionsEdit.vue'),
    meta: {
      title: 'КСП | Редактирование вопроса для чат-бота',
    },
  },
  // chat-bot answers
  {
    path: '/customers/chat-bot-answers',
    name: 'ChatbotAnswers',
    component: () => import('@/views/customers/ChatbotAnswers.vue'),
    meta: {
      title: 'КСП | Вопросы для чат-бота',
    },
  },
  {
    path: '/customers/chat-bot-answers/create',
    name: 'ChatbotAnswersCreate',
    component: () => import('@/views/customers/ChatbotAnswersNew.vue'),
    meta: {
      title: 'КСП | Создание вопроса для чат-бота',
    },
  },
  {
    path: '/customers/chat-bot-answers/edit/:id',
    name: 'ChatbotAnswersEdit',
    component: () => import('@/views/customers/ChatbotAnswersEdit.vue'),
    meta: {
      title: 'КСП | Редактирование вопроса для чат-бота',
    },
  },
  // start pages
  {
    path: '/customers/start-pages',
    name: 'StartPages',
    component: () => import('@/views/customers/StartPages.vue'),
    meta: {
      title: 'КСП | Стартовые страницы',
    },
  },
  {
    path: '/customers/start-pages/create',
    name: 'StartPagesCreate',
    component: () => import('@/views/customers/StartPagesNew.vue'),
    meta: {
      title: 'КСП | Создание стартовой страницы',
    },
  },
  {
    path: '/customers/start-pages/edit/:id',
    name: 'StartPagesEdit',
    component: () => import('@/views/customers/StartPagesEdit.vue'),
    meta: {
      title: 'КСП | Редактирование стартовой страницы',
    },
  },
  // accounsts
  {
    path: '/customers/accounts',
    name: 'Accounts',
    component: () => import('@/views/customers/Accounts.vue'),
    meta: {
      title: 'КСП | Аккаунты',
    },
  },
  {
    path: '/customers/debtors',
    name: 'DebtorList',
    component: () => import('@/views/customers/DebtorList.vue'),
    meta: {
      title: 'КСП | Список должников',
    },
  },
  {
    path: '/customers/accounts/create',
    name: 'AccountCreate',
    component: () => import('@/views/customers/AccountCreate.vue'),
    meta: {
      title: 'КСП | Создание аккаунта',
    },
  },
  {
    path: '/customers/accounts/edit/:id',
    name: 'AccountEdit',
    component: () => import('@/views/customers/AccountEdit.vue'),
    meta: {
      title: 'КСП | Редактирование аккаунта',
    },
  },
  {
    path: '/customers/accounts/customers-for-account/:id',
    name: 'CustomersForAccount',
    component: () => import('@/views/customers/CustomersForAccount.vue'),
    meta: {
      title: 'КСП | Клиенты аккаунта',
    },
  },

  // accounts for customer
  {
    path: '/customers/accounts-for-customer/:id',
    name: 'AccountsForCustomer',
    component: () => import('@/views/customers/AccountsForCustomer.vue'),
    meta: {
      title: 'КСП | Аккаунты клиента',
    },
  },
  {
    path: '/customers/accounts-for-customer/:parentId/edit/:id',
    name: 'AccountForCustomerEdit',
    component: () => import('@/views/customers/AccountForCustomerEdit.vue'),
    meta: {
      title: 'КСП | Редактирование аккаунта',
    },
    props: true,
  },

  // sites for customer
  {
    path: '/customers/sites-for-customer/:id',
    name: 'SitesForCustomer',
    component: () => import('@/views/customers/SitesForCustomer.vue'),
    meta: {
      title: 'КСП | Сайты клиентов',
    },
  },
  {
    path: '/customers/site-for-customer/:parentId/edit/:id',
    name: 'SiteForCustomerEdit',
    component: () => import('@/views/customers/SiteForCustomerEdit.vue'),
    meta: {
      title: 'КСП | Редактирование сайта клиентов',
    },
    props: true,
  },

  // one ccontracts for customer
  {
    path: '/customers/one-ccontracts-for-customer/:id',
    name: 'OneCContractsForCustomer',
    component: () => import('@/views/customers/OneCContractsForCustomer.vue'),
    meta: {
      title: 'КСП | Договоры',
    },
  },

  // one ccontacts for customer
  {
    path: '/customers/one-ccontacts-for-customer/:id',
    name: 'OneCContactsForCustomer',
    component: () => import('@/views/customers/OneCContactsForCustomer.vue'),
    meta: {
      title: 'КСП | Контакты',
    },
  },

  // sites
  {
    path: '/customers/sites',
    name: 'Sites',
    component: () => import('@/views/customers/Sites.vue'),
    meta: {
      title: 'КСП | Сайты клиентов',
    },
  },
  {
    path: '/customers/sites/create',
    name: 'SiteCreate',
    component: () => import('@/views/customers/SiteCreate.vue'),
    meta: {
      title: 'КСП | Создание cайта клиентов',
    },
  },
  {
    path: '/customers/sites/edit/:id',
    name: 'SiteEdit',
    component: () => import('@/views/customers/SiteEdit.vue'),
    meta: {
      title: 'КСП | Редактирование cайта клиентов',
    },
  },
  {
    path: '/customers/contracts/create',
    name: 'ContractCreate',
    component: () => import('@/views/customers/ContractCreate.vue'),
    meta: {
      title: 'КСП | Создание договоров',
    },
  },

  // technical support help tree
  {
    path: '/customers/technical-support-help-tree',
    name: 'TechnicalSupportHelpTree',
    component: () => import('@/views/customers/TechnicalSupportHelpTree.vue'),
    meta: {
      title: 'КСП | Дерево решений по тех. поддержке',
    },
  },
  {
    path: '/customers/technical-support-help-tree/create',
    name: 'TechnicalSupportHelpTreeCreate',
    component: () =>
      import('@/views/customers/TechnicalSupportHelpTreeCreate.vue'),
    meta: {
      title: 'КСП | Создание дерева решений по тех. поддержке',
    },
  },
  {
    path: '/customers/technical-support-help-tree/:id',
    name: 'TechnicalSupportHelpTreeEdit',
    component: () =>
      import('@/views/customers/TechnicalSupportHelpTreeEdit.vue'),
    meta: {
      title: 'КСП | Редактирование дерева решений по тех. поддержке',
    },
  },

  // tarrifs calls
  {
    path: '/customers/tarrifs-calls',
    name: 'TariffCalls',
    component: () => import('@/views/customers/TariffCalls.vue'),
    meta: {
      title: 'Тарифы на звонки',
    },
  },
  {
    path: '/customers/tarrifs-calls/create',
    name: 'TariffCallCreate',
    component: () => import('@/views/customers/TariffCallCreate.vue'),
    meta: {
      title: 'КСП | Создание тарифа на звонки',
    },
  },
  {
    path: '/customers/tarrifs-calls/edit/:id',
    name: 'TariffCallEdit',
    component: () => import('@/views/customers/TariffCallEdit.vue'),
    meta: {
      title: 'КСП | Редактирование тарифа на звонки',
    },
  },
  // [ END ] customers

  // [ START ] telemarketing
  {
    path: '/telemarketing',
    name: 'TelemarketingMain',
    component: () => import('@/views/telemarketing/Main.vue'),
    meta: {
      title: 'КСП | Телемаркетинг',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-create-many',
    name: 'TelemarketingTasksCreateMany',
    component: () =>
      import('@/views/telemarketing/TelemarketingTasksCreateMany.vue'),
    meta: {
      title: 'КСП | Множественное создание задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-for-current-user',
    name: 'TelemarketingTasksForCurrentUser',
    component: () =>
      import('@/views/telemarketing/TelemarketingTasksForCurrentUser.vue'),
    meta: {
      title: 'КСП | Задания по телемаркетингу для текущего пользователя',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-for-current-user/create',
    name: 'TelemarketingTasksForCurrentUserReminderCreate',
    component: () =>
      import(
        '@/views/telemarketing/TelemarketingTasksForCurrentUserReminderCreate.vue'
      ),
    meta: {
      title: 'КСП | Создание напоминания',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-for-current-user/edit/:id',
    name: 'TelemarketingTaskForCurrentUserEdit',
    component: () =>
      import('@/views/telemarketing/TelemarketingTaskForCurrentUserEdit.vue'),
    meta: {
      title:
        'КСП | Редактирование задания по телемаркетингу для текущего пользователя',
    },
  },

  {
    path: '/telemarketing/one-ccontracts-for-telemarketing-current-user/:id/inn/:inn',
    name: 'OneCContractsForTelemarketingTaskCurrentUser',
    component: () =>
      import(
        '@/views/telemarketing/OneCContractsForTelemarketingTaskCurrentUser.vue'
      ),
    meta: {
      title: 'КСП | Договоры',
    },
  },

  {
    path: '/telemarketing/one-ccontacts-for-telemarketing-current-user/:id/inn/:inn',
    name: 'OneCContactsForTelemarketingTaskCurrentUser',
    component: () =>
      import(
        '@/views/telemarketing/OneCContactsForTelemarketingTaskCurrentUser.vue'
      ),
    meta: {
      title: 'КСП | Контакты',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks',
    name: 'TelemarketingTasks',
    component: () => import('@/views/telemarketing/TelemarketingTasks.vue'),
    meta: {
      title: 'КСП | Задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks/create',
    name: 'TelemarketingTaskCreate',
    component: () =>
      import('@/views/telemarketing/TelemarketingTaskCreate.vue'),
    meta: {
      title: 'КСП | Создание задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks/edit/:id',
    name: 'TelemarketingTaskEdit',
    component: () => import('@/views/telemarketing/TelemarketingTaskEdit.vue'),
    meta: {
      title: 'КСП | Редактирование задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/one-ccontracts-for-telemarketing/:id/inn/:inn',
    name: 'OneCContractsForTelemarketingTask',
    component: () =>
      import('@/views/telemarketing/OneCContractsForTelemarketingTask.vue'),
    meta: {
      title: 'КСП | Договоры',
    },
  },

  {
    path: '/telemarketing/one-ccontacts-for-telemarketing/:id/inn/:inn',
    name: 'OneCContactsForTelemarketingTask',
    component: () =>
      import('@/views/telemarketing/OneCContactsForTelemarketingTask.vue'),
    meta: {
      title: 'КСП | Контакты',
    },
  },

  {
    path: '/telemarketing/organizations',
    name: 'Organizations',
    component: () => import('@/views/telemarketing/Organizations.vue'),
    meta: {
      title: 'КСП | Организации',
    },
  },

  {
    path: '/telemarketing/organizations/edit/:id',
    name: 'OrganizationEdit',
    component: () => import('@/views/telemarketing/OrganizationEdit.vue'),
    meta: {
      title: 'КСП | Просмотр организации',
    },
  },

  {
    path: '/telemarketing/one-ccontracts-for-organization/:id/inn/:inn/organizationName/:organizationName',
    name: 'OneCContractsForOrganization',
    component: () =>
      import('@/views/telemarketing/OneCContractsForOrganization.vue'),
    meta: {
      title: 'КСП | Договоры',
    },
  },

  {
    path: '/telemarketing/one-ccontacts-for-organization/:id/inn/:inn/organizationName/:organizationName',
    name: 'OneCContactsForOrganization',
    component: () =>
      import('@/views/telemarketing/OneCContactsForOrganization.vue'),
    meta: {
      title: 'КСП | Контакты',
    },
  },

  {
    path: '/telemarketing/reminders/create',
    name: 'TelemarketingReminderCreate',
    component: () =>
      import('@/views/telemarketing/TelemarketingReminderCreate.vue'),
    meta: {
      title: 'КСП | Создание напоминания',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-rejection-reasons',
    name: 'TelemarketingTasksRejectionReasons',
    component: () =>
      import('@/views/telemarketing/TelemarketingTasksRejectionReasons.vue'),
    meta: {
      title: 'КСП | Задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-rejection-reasons/create',
    name: 'TelemarketingTasksRejectionReasonCreate',
    component: () =>
      import(
        '@/views/telemarketing/TelemarketingTasksRejectionReasonCreate.vue'
      ),
    meta: {
      title: 'КСП | Создание задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/telemarketing-tasks-rejection-reasons/edit/:id',
    name: 'TelemarketingTasksRejectionReasonEdit',
    component: () =>
      import('@/views/telemarketing/TelemarketingTasksRejectionReasonEdit.vue'),
    meta: {
      title: 'КСП | Редактирование задания по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/calls',
    name: 'SipCalls',
    component: () => import('@/views/telemarketing/SipCalls.vue'),
    meta: {
      title: 'КСП | Звонки по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/sip-calls-for-telemarketing-task/:id',
    name: 'SipCallsForTelemarketingTask',
    component: () =>
      import('@/views/telemarketing/SipCallsForTelemarketingTask.vue'),
    meta: {
      title: 'КСП | Звонки по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/sip-calls-for-telemarketing-task-current-user/:id',
    name: 'SipCallsForTelemarketingTaskCurrentUser',
    component: () =>
      import(
        '@/views/telemarketing/SipCallsForTelemarketingTaskCurrentUser.vue'
      ),
    meta: {
      title: 'КСП | Звонки по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-scripts',
    name: 'TelemarketingScripts',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingScripts.vue'),
    meta: {
      title: 'КСП | Скрипты телемаркетинга',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-scripts/create',
    name: 'TelemarketingScriptCreate',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingScriptCreate.vue'),
    meta: {
      title: 'КСП | Создание скрипта телемаркетинга',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-scripts/edit/:id',
    name: 'TelemarketingScriptEdit',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingScriptEdit.vue'),
    meta: {
      title: 'КСП | Редактирование скрипта телемаркетинга',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-questions',
    name: 'TelemarketingQuestions',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingQuestions.vue'),
    meta: {
      title: 'КСП | Вопросы по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-questions/create',
    name: 'TelemarketingQuestionsCreate',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingQuestionsCreate.vue'),
    meta: {
      title: 'КСП | Создание вопроса по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-questions/edit/:id',
    name: 'TelemarketingQuestionsEdit',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingQuestionsEdit.vue'),
    meta: {
      title: 'КСП | Редактирование вопроса по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-questions-for-script/:id',
    name: 'TelemarketingQuestionsForScript',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingQuestionsForScript.vue'),
    meta: {
      title: 'КСП | Вопросы по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-questions-for-script/:id/create',
    name: 'TelemarketingQuestionsForScriptCreate',
    component: () =>
      import(
        '@/views/telemarketing/tasks/TelemarketingQuestionsForScriptCreate.vue'
      ),
    meta: {
      title: 'КСП | Создание вопроса по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-questions-for-script/:parentId/edit/:id',
    name: 'TelemarketingQuestionsForScriptEdit',
    component: () =>
      import(
        '@/views/telemarketing/tasks/TelemarketingQuestionsForScriptEdit.vue'
      ),
    meta: {
      title: 'КСП | Редактирование вопроса по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-products',
    name: 'TelemarketingProducts',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingProducts.vue'),
    meta: {
      title: 'КСП | Продукты телемаркетинга',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-products/create',
    name: 'TelemarketingProductCreate',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingProductCreate.vue'),
    meta: {
      title: 'КСП | Создание продукта телемаркетинга',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-products/edit/:id',
    name: 'TelemarketingProductEdit',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingProductEdit.vue'),
    meta: {
      title: 'КСП | Редактирование продукта телемаркетинга',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-report',
    name: 'TelemarketingReport',
    component: () => import('@/views/telemarketing/TelemarketingReport.vue'),
    meta: {
      title: 'КСП | Отчет по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/organizations-report',
    name: 'OrganizationsReport',
    component: () => import('@/views/telemarketing/OrganizationsReport.vue'),
    meta: {
      title: 'КСП | Отчет по организациям',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-performing-report',
    name: 'TelemarketingPerformingReport',
    component: () =>
      import('@/views/telemarketing/TelemarketingPerformingReport.vue'),
    meta: {
      title: 'КСП | Отчет по выполнению заданий по ТМ',
    },
  },

  {
    path: '/telemarketing/send-mail-history',
    name: 'SendMailHistory',
    component: () => import('@/views/telemarketing/SendMailHistory.vue'),
    meta: {
      title: 'КСП | Логи отправки писем',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-check-list-questions',
    name: 'TelemarketingCheckListQuestions',
    component: () =>
      import('@/views/telemarketing/tasks/TelemarketingCheckListQuestions.vue'),
    meta: {
      title: 'КСП | Вопросы для чек-листа по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-check-list-questions/create',
    name: 'TelemarketingCheckListQuestionsCreate',
    component: () =>
      import(
        '@/views/telemarketing/tasks/TelemarketingCheckListQuestionsCreate.vue'
      ),
    meta: {
      title: 'КСП | Создание вопроса для чек-листа по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-check-list-questions/edit/:id',
    name: 'TelemarketingCheckListQuestionsEdit',
    component: () =>
      import(
        '@/views/telemarketing/tasks/TelemarketingCheckListQuestionsEdit.vue'
      ),
    meta: {
      title: 'КСП | Редактирование вопроса для чек-листа по телемаркетингу',
    },
  },

  {
    path: '/telemarketing/tasks/telemarketing-check-list-report',
    name: 'TelemarketingCheckListReport',
    component: () =>
      import('@/views/telemarketing/TelemarketingCheckListReport.vue'),
    meta: {
      title: 'КСП | Отчет по чек-листам телемаркетинга',
    },
  },
  // [ END ] telemarketing

  // [ START ] requests
  {
    path: '/requests',
    name: 'RequestsMain',
    component: () => import('@/views/requests/Main.vue'),
    meta: {
      title: 'КСП | Заявки',
    },
  },
  {
    path: '/requests/requests',
    name: 'SupportRequests',
    component: () => import('@/views/requests/SupportRequests.vue'),
    meta: {
      title: 'КСП | Заявки',
    },
  },
  {
    path: '/requests/requests/edit/:id',
    name: 'SupportRequestEdit',
    component: () => import('@/views/requests/SupportRequestEdit.vue'),
    meta: {
      title: 'КСП | Редактирование заявки',
    },
  },
  {
    path: '/requests/requests-for-current-user',
    name: 'SupportRequestsForCurrentUser',
    component: () =>
      import('@/views/requests/SupportRequestsForCurrentUser.vue'),
    meta: {
      title: 'КСП | Мои заявки',
    },
  },
  {
    path: '/requests/requests-for-current-user/create',
    name: 'SupportRequestForCurrentUserCreate',
    component: () =>
      import('@/views/requests/SupportRequestForCurrentUserCreate.vue'),
    meta: {
      title: 'КСП | Создание заявки',
    },
  },
  {
    path: '/requests/requests-for-current-user/edit/:id',
    name: 'SupportRequestForCurrentUserEdit',
    component: () =>
      import('@/views/requests/SupportRequestForCurrentUserEdit.vue'),
    meta: {
      title: 'КСП | Мои заявки',
    },
  },
  {
    path: '/requests/support-request-info/:id',
    name: 'SupportRequestInfo',
    component: () => import('@/views/requests/SupportRequestInfo.vue'),
    meta: {
      title: 'КСП | Информация о заявке',
    },
  },
  // [ END ] requests

  // [ START ] publishing applications
  {
    path: '/publishing-applications',
    name: 'PublishingApplicationsMain',
    component: () => import('@/views/publishing-applications/Main.vue'),
    meta: {
      title: 'КСП | Публикация приложений',
    },
  },

  {
    path: '/publishing-applications/deploy-project',
    name: 'DeployProject',
    component: () =>
      import('@/views/publishing-applications/DeployProject.vue'),
    meta: {
      title: 'КСП | Публикуемые проекты',
    },
  },
  {
    path: '/publishing-applications/deploy-project/create',
    name: 'DeployProjectCreate',
    component: () =>
      import('@/views/publishing-applications/DeployProjectCreate.vue'),
    meta: {
      title: 'КСП | Создание публикации приложения',
    },
  },
  {
    path: '/publishing-applications/deploy-project/:id',
    name: 'DeployProjectEdit',
    component: () =>
      import('@/views/publishing-applications/DeployProjectEdit.vue'),
    meta: {
      title: 'КСП | Редактирование публикации приложения',
    },
  },

  // deploy
  {
    path: '/publishing-applications/deploy',
    name: 'Deploy',
    component: () => import('@/views/publishing-applications/Deploy.vue'),
    meta: {
      title: 'КСП | Проекты',
    },
  },
  {
    path: '/publishing-applications/deploy-detail/:id',
    name: 'DeployDetail',
    component: () => import('@/views/publishing-applications/DeployDetail.vue'),
    meta: {
      title: 'КСП | Проект',
    },
  },

  // release info
  {
    path: '/publishing-applications/deploy-detail/:deployId/release-info/:id',
    name: 'ReleaseInfo',
    props: true,
    component: () => import('@/views/publishing-applications/ReleaseInfo.vue'),
    meta: {
      title: 'КСП | Информации по релизу',
    },
  },

  // release publication info
  {
    path: '/publishing-applications/deploy-detail/:deployId/release-info/:releaseId/release-publication-info/:id',
    name: 'ReleasePublicationInfo',
    props: true,
    component: () =>
      import('@/views/publishing-applications/ReleasePublicationInfo.vue'),
    meta: {
      title: 'КСП | Информации о публикации релиза',
    },
  },

  // custom periodic tasks
  {
    path: '/publishing-applications/custom-periodic-tasks',
    name: 'CustomPeriodicTasks',
    component: () =>
      import('@/views/publishing-applications/CustomPeriodicTasks.vue'),
    meta: {
      title: 'КСП | Кастомные периодические задачи',
    },
  },
  {
    path: '/publishing-applications/custom-periodic-tasks/create',
    name: 'CustomPeriodicTaskCreate',
    component: () =>
      import('@/views/publishing-applications/CustomPeriodicTaskCreate.vue'),
    meta: {
      title: 'КСП | Создание кастомной периодической задачи',
    },
  },
  {
    path: '/publishing-applications/custom-periodic-tasks/create/:id',
    name: 'CustomPeriodicTaskEdit',
    component: () =>
      import('@/views/publishing-applications/CustomPeriodicTaskEdit.vue'),
    meta: {
      title: 'КСП | Редактирование кастомной периодической задачи',
    },
  },
  {
    path: '/requests/custom-periodic-tasks-log-for-custom-periodic-tasks/edit/:id',
    name: 'CustomPeriodicTasksLogForCustomPeriodicTasks',
    component: () =>
      import(
        '@/views/publishing-applications/CustomPeriodicTasksLogForCustomPeriodicTasks.vue'
      ),
    meta: {
      title: 'КСП | Мои заявки',
    },
  },
  // custom periodic tasks log
  {
    path: '/publishing-applications/custom-periodic-tasks-log',
    name: 'CustomPeriodicTasksLog',
    component: () =>
      import('@/views/publishing-applications/CustomPeriodicTasksLog.vue'),
    meta: {
      title: 'КСП | Логи кастомных переодических задач',
    },
  },
  // [ END ] publishing applications

  // [ START ] reminders
  {
    path: '/reminders/reminders',
    name: 'Reminders',
    component: () => import('@/views/reminders/Reminders.vue'),
    meta: {
      title: 'КСП | Напоминания',
    },
  },
  {
    path: '/reminders/create',
    name: 'ReminderCreate',
    component: () => import('@/views/reminders/ReminderCreate.vue'),
    meta: {
      title: 'КСП | Создание напоминания',
    },
  },
  {
    path: '/reminders/edit/:id',
    name: 'ReminderEdit',
    component: () => import('@/views/reminders/ReminderEdit.vue'),
    meta: {
      title: 'КСП | Редактирование напоминания',
    },
  },
  // [ END ] reminders

  // emails
  {
    path: '/administration/email',
    name: 'MailBoxesMessages',
    component: () => import('@/views/administration/MailBoxesMessages.vue'),
    meta: {
      title: 'Почта',
    },
  },
  {
    path: '/administration/email/:id',
    name: 'MailBoxesSingleMessage',
    component: () =>
      import('@/views/administration/MailBoxesSingleMessage.vue'),
    meta: {
      title: 'Просмотр письма',
    },
  },
  // [ END ] emails
];

export default authorizedRoutes.map((route) => {
  route.meta = {
    middleware: { unauthorizedOnly: false },
    layout: 'Default',
    ...route.meta,
  };
  return route;
});
